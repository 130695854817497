<template>
  <section
    class="BankSection"
  >
    <!-- style="padding: 80px 15px 15px 265px; padding-top: 180px; background-color: transparent" -->
    <div>
      <div style="display: flex; align-items: end; gap: 10px; padding-bottom: 15px;">
        <AddBankHolidays @pushBankHoliday="BankHoliday" />
        <EditBankHolidays
          :form="form"
          @editBankHoliday="editBankHoliday"
        />
        <BankHolidaysToggleEdit
          :form="form"
          @onCancelToggle="onCancelToggle"
          @editBankHoliday="editBankHoliday"
        />
        <div
          v-if="isSidebarOpen"
          class="backdrop"
        />
        <b-form-group
          id="input-group-9"
          :label="$t('FilterDate')"
          label-for="input-9"
          style="margin-bottom: 10px"
        >
          <div class="year">
            <button
              class="nextmonth"
              @click="previousDay"
            >
              <b-icon-arrow-left />
            </button>
            <date-picker
              v-model="selectedDate"
              value-type="format"
              class="form-control"
              format="YYYY"
              type="date"
              :placeholder="$t('SelectD')"
              style="width: 193px; padding-bottom: 0px; display: flex; align-items: flex-end;"
            />
            <button
              style="margin-left: 5px"
              class="nextmonth"
              @click="nextDay"
            >
              <b-icon-arrow-right />
            </button>
          </div>
        </b-form-group>
        <button
          v-b-tooltip.top
          v-b-modal.confirmation
          :title="$t('MovePrev')"
          class="button"
          style="width: 220px !important; height: 37px; background: #FF274F; margin-top: 8px; margin-bottom: 0px !important; border: none;"
        >
          {{ $t('MoveAllDays') }}
        </button>
      </div>
      <div
        style="width: 100%;  padding-top: 16px; height: 82vh; overflow-y: scroll;"
        class="scroll"
      >
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>
                {{ $t("NameOfHoliday") }}
              </th>
              <th>
                {{ $t("NumberOfDays") }}
              </th>
              <th>
                {{ $t("Date") }}
              </th>
              <th>
                {{ $t("Edit") }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(item, index) in getBankHolidays"
              :key="index.id"
              style="
                border-bottom: 1px solid #dcdfe6;
                color: #606266;
                text-align: center;
                cursor: pointer;
              "
            >
              <!-- @click="matProps(user), showSameId(user.userId), loadVacation(user.userId)" -->
              <!-- <td style="padding: 9px">
                {{ getTotalItemsForUsers - (page - 1) * pageSize - index }}.
              </td> -->
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.noOfDays }}
              </td>
              <td>
                {{ dateFormat(item.bankDate) }}
              </td>
              <td
                id="edit"
                style="cursor: pointer; display: flex;justify-content: center;gap: 10px;"
              >
                <p
                  v-b-toggle.edit-bankholiday
                  class="p-0 m-0 ml-50"
                >
                  <button
                    class="editButton"
                    @click="getBankHolidayData(item)"
                  >
                    {{ $t('Edit') }}
                  </button>
                </p>
                <!-- <p
                  class="p-0 m-0 ml-50"
                >
                  <b-icon-trash />
                </p> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ConfirmationModalHolidays
      @confirmationModal="handleCopyBankHolidays"
    />
  </section>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment-timezone'
import DatePicker from 'vue2-datepicker';
import BankHolidaysToggleEdit from '@/components/BankHolidaysToggleEdit.vue'
import AddBankHolidays from './teams/modals/AddBankHolidays.vue';
import EditBankHolidays from './teams/modals/EditBankHolidays.vue';
import ConfirmationModalHolidays from './ConfirmationModalHolidays.vue'
import 'vue2-datepicker/index.css';






export default {
  components: {
    AddBankHolidays,
    EditBankHolidays,
    DatePicker,
    BankHolidaysToggleEdit,
    ConfirmationModalHolidays,
  },
  data() {
    return {
      isSidebarOpen: false,
      countryStateId: '3f1b57e7-4dc1-424d-893f-08db31f6edb4',
      selectedDate: moment().format('YYYY-MM-DD'),
      form: {},
    }
  },
  computed: {
    ...mapGetters([
      'getBankHolidays',
      'getCantons',
      'getIsLoading',
    ]),

  },
  watch: {
    countryStateId(value) {
      this.loadBankHolidays({
        countryStateId: value,
        year: this.selectedDate,
      });
    },
    selectedDate(value) {
      this.loadBankHolidays({
        countryStateId: this.countryStateId,
        year: value,
      })
    },
  },
  mounted() {
    this.loadCountryStates();
    this.loadBankHolidays({
      countryStateId: this.countryStateId,
      year: this.selectedDate,
    });
  },
  methods: {
    ...mapActions(['bankHolidays', 'loadBankHolidays', 'loadCountryStates', 'editBankHolidays', 'copyBankHolidays']),
    onCancelToggle() {
      this.isSidebarOpen = false
    },
    BankHoliday(obj) {
      this.bankHolidays({
        object: obj,
        successCallback: () => {
          this.loadBankHolidays({
            countryStateId: this.countryStateId,
            year: this.selectedDate,
          });
        },
      });
    },
    async handleCopyBankHolidays() {
      const previousYear = moment(this.selectedDate, 'YYYY-MM-DD').subtract(1, 'year').format('YYYY-MM-DD');
      await this.copyBankHolidays({
        countryStateId: this.countryStateId,
        year: previousYear,
        successCallback: () => {
          this.loadBankHolidays({
            countryStateId: this.countryStateId,
            year: this.selectedDate,
          });
        },
      })
    },
    editBankHoliday(value) {
      this.editBankHolidays({ bankHolidayId: value.bankHolidayId, object: { bankHolidayName: value.name, bankHolidayDate: value.bankDate, noOfDays: value.noOfDays } })
    },
    getBankHolidayData(value) {
      this.isSidebarOpen = true
      this.form = value;
    },
    // async fetchCountryStates() {
    //   const year = '2023-07-11';
    //   const countryStateId = '3f1b57e7-4dc1-424d-893f-08db31f6edb4'
    //   await this.loadBankHolidays({ countryStateId, year });
    // },
    previousDay() {
      this.selectedDate = moment(this.selectedDate, 'YYYY-MM-DD').subtract(1, 'year').format('YYYY-MM-DD');
    },
    nextDay() {
      this.selectedDate = moment(this.selectedDate, 'YYYY-MM-DD').add(1, 'year').format('YYYY-MM-DD');
    },
    dateFormat(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    // moveAllDays() {
    //   const bankHolidays2023 = this.getBankHolidays;

    //   const bankHolidays2024 = bankHolidays2023.map((holiday) => {
    //     return {
    //       bankHolidayName: holiday.name,
    //       bankHolidayDate: moment(holiday.bankDate, 'YYYY-MM-DD').add(1, 'year').format('YYYY-MM-DD'),
    //       noOfDays: holiday.noOfDays,
    //       bankHolidayId: holiday.bankHolidayId,
    //     };
    //   });

    //   const allBankHolidays = [...bankHolidays2024];


    //   this.bankHolidays({
    //     object: {
    //       countryStateId: this.countryStateId,
    //       bankHolidayDtos: allBankHolidays,
    //     },
    //   });
    // },
  },


};
</script>

<style scoped>

/* .editButton {
  background: #FF274F;
  width: 75px !important;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 500;
} */

.team_table td {
  padding: 11px 9px !important;
}
.team_table th {
  padding: 10px;
  text-align: center;
}
.BankSection{
  padding: 0px 15px 15px 0px;
}
.form-group{
  margin-bottom: 0px !important;
}

.form-control{
  width: 193px;
  border: none;
}
.year {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
  .nextmonth {
    width: 36px !important;
    height: 36px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
  }
  h5 {
    border: #b4b4b4 2px solid;
    border-radius: 6px;
    background-color: whitesmoke;
    width: 40%;
    height: 36px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }

  @media screen and (max-width: 1100px){
    .BankSection{
      padding: 75px 15px 15px 20px;
    }
  }

  @media screen and (max-width: 1200px){
    .BankSection{
      padding: 0px 15px 15px 5px;
    }
  }

</style>
