<template>
  <div>
    <b-modal
      id="edit-bankholiday"
      ref="modal"
      v-model="modalShow"
      hide-footer
      :title="$t('EditHoliday')"
      @close="hideModal"
    >
      <div>
        <b-form-group
          :label="$t('SelectBankHoliday')"
          label-for="countryState"
        >
          <b-form-input
            id="CountryState"
            v-model="form.name"
          />
        </b-form-group>
        <b-form-group
          :label="$t('SelectDays')"
          label-for="countryState"
        >
          <b-form-input
            id="CountryState"
            v-model.number="form.noOfDays"
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('EmployeeStartingDay')"
          label-for="input-1"
        >
          <date-picker
            v-model="form.bankDate"
            style="width: 100%"
            value-type="format"
            :placeholder="$t('SelectD')"
            :format="'YYYY-MM-DD'"
          />
        </b-form-group>
      </div>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmitAddUser"
        >
          {{ $t('Save') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';




export default {
  components: {
    // flatPickr,
    DatePicker,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['form'],
  data() {
    return {
      modalShow: false,
      inputValue: '',
    };
  },
  computed: {
    ...mapGetters([
      'getCantons',
    ]),
  },
  mounted() {
    if (this.form && this.form.bankHolidayDtos) {
      this.form.bankHolidayDtos.push({
        bankHolidayName: '',
        noOfDays: 0,
        bankHolidayDate: new Date(),
      });
    }
    // this.loadCountryStates()
  },
  methods: {
    ...mapActions(['bankHolidays', 'loadCountryStates']),
    showModal() {
      this.modalShow = true;
    },
    hideModal() {
      this.modalShow = false;
    },
    saveData() {
    },
    onModalHide() {
    },
    async onSubmitAddUser() {
      console.log(this.form)
      this.$emit('editBankHoliday', this.form);
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.form.bankHolidayDtos.bankHolidayName = '';
      this.form.bankHolidayDtos.countryStateId = '';
      this.form.bankHolidayDtos.bankHolidayDate = '';
      this.form.bankHolidayDtos.noOfDays = '';


      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

<style scoped>
.buttonii{
  background-color: #242F6E;
    font-weight: 500;
    color: #FBFAF7;
    border: none;
    padding: 6px 12px;
    border-radius: 6px;
    height: 42px;
    margin-top: 25px;
    margin-right: 20px;
    width: 100%;
}
</style>
