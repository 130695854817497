<template>
  <div>
    <b-modal
      id="confirmation"
      ref="modal"
      header-class="headerModal"
      centered
      size="md"
      :title="$t('Confirmation')"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group>
          {{ $t('AreUSure') }}
        </b-form-group>
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-left: 5px; background: #FF274F !important;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="background: white; color: black; border-color: white;font-weight: 400;"

            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

export default {

  data() {
    return {

    }
  },
  methods: {
    onSubmit() {
      this.$emit('confirmationModal')
      this.$refs.modal.hide()
    },
    onCancel() {
      this.$refs.modal.hide()
    },
  },
}
</script>
