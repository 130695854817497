<template>
  <div>
    <b-button
      class="button"
      @click="showModal"
    >
      {{ $t('AddBankHoliday') }}
    </b-button>

    <b-modal
      ref="modal"
      v-model="modalShow"
      :title="$t('AddBankHoliday')"
      hide-footer
      size="l"
      header-class="headerModal"
      @hide="onModalHide"
    >
      <!-- <b-form-group
        label="Select Country State name"
        label-for="countryState"
      >
        <vue-select
          id="Name"
          v-model="form.countryStateId"
          :options="getCantons"
          :reduce="(e) => e.countryStateId"
          label="name"
        />
      </b-form-group> -->
      <div
        v-for="(item, index) in form.bankHolidayDtos"
        :key="index"
      >

        <b-form-group
          id="holiday"
          :label="$t('SelectBankHoliday')"
          label-for="countryState"
        >
          <b-form-input
            id="CountryState"
            v-model="item.bankHolidayName"
          />
        </b-form-group>
        <b-form-group
          id="days"
          :label="$t('SelectDays')"
          label-for="countryState"
        >
          <b-form-input
            id="CountryState"
            v-model.number="item.noOfDays"
          />
        </b-form-group>
        <b-form-group
          id="input-group-1"
          :label="$t('EmployeeStartingDay')"
          label-for="input-1"
        >
          <date-picker
            v-model="item.bankHolidayDate"
            style="width: 100%"
            value-type="format"
            :placeholder="$t('SelectD')"
            :format="'YYYY-MM-DD'"
          />
        </b-form-group>
      </div>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmitAddUser"
        >
          {{ $t('Save') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';




export default {
  components: {
    // flatPickr,
    DatePicker,
  },
  data() {
    return {
      modalShow: false,
      inputValue: '',
      form: {
        countryStateId: '3f1b57e7-4dc1-424d-893f-08db31f6edb4',
        bankHolidayDtos: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCantons',
    ]),
  },
  mounted() {
    this.form.bankHolidayDtos.push({
      bankHolidayName: '',
      noOfDays: 0,
      bankHolidayDate: Date,
    })
    this.loadCountryStates()
  },
  methods: {
    ...mapActions(['bankHolidays', 'loadCountryStates']),
    showModal() {
      this.modalShow = true;
    },
    hideModal() {
      this.modalShow = false;
    },
    saveData() {
    },
    onModalHide() {
    },
    async onSubmitAddUser() {
      console.log(this.form)
      this.$emit('pushBankHoliday', this.form);
      this.$refs.modal.hide();
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.form.bankHolidayDtos.bankHolidayName = '';
      this.form.bankHolidayDtos.countryStateId = '';
      this.form.bankHolidayDtos.bankHolidayDate = '';
      this.form.bankHolidayDtos.noOfDays = '';


      // Trick to reset/clear native browser form validation state
    },
  },
};
</script>

<style scoped>
.buttonii{
  border-radius: 6px;
    background: linear-gradient(52deg, rgb(38, 46, 108) 0%, rgb(155, 42, 92) 100%, rgb(255, 39, 79) 100%) 0% 0% no-repeat padding-box padding-box transparent;
    color: rgb(244, 241, 237);
    border-color: transparent;
    margin-top: 25px;
    margin-right: 20px;
    width: 100%;
}
</style>
