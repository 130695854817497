<!-- <template>
  <div>
    <BankHolidays />

    <VacationPlanEmployee />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import BankHolidays from '../components/BankHolidays.vue'
import Spinner from '../components/Spinner/Spinner.vue'


export default {
  components: {
    BankHolidays,
    Spinner,
  },
  computed: {
    ...mapGetters(['getIsLoading']),

  },
}

</script> -->
<template>
  <div >
    <section
      class="main no-flex"
      style="margin-top: 0px;"
    >

      <OurBankHolidays />
      <!-- <UsersAttendance
        :user="user"
      /> -->
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from 'vuex'
// import SaturdayWorkingHours from '../components/SaturdayWorkingHoursListing.vue'
// import UsersAttendance from '@/components/properties/UsersAttedance.vue'
import OurBankHolidays from '../components/OurBankHolidays.vue'


export default {
  name: 'Home',
  components: {
    OurBankHolidays,
    // UsersAttendance,
  },
  data() {
    return {
      user: '',
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),

  },
  methods: {
    materialId(id) {
      this.materialIdToShow = id
    },
    handleUser(id) {
      this.user = id;
    },
  },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 1600px) {
  .main{
    padding: 75px 15px 15px 90px;
  }
}
@media screen and (max-width: 1340px){
  .main{
    padding: 75px 15px 15px 80px;
  }
}
@media screen and (max-width: 1041px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}

@media screen and (max-width: 1200px) {
  .main {
    padding: 75px 0px 15px 10px;
  }
}
</style>
